import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/auth.guard'
import { LabelSettingsComponent } from './label-settings/label-settings.component';
import { ParameterSettingsComponent } from './parameter-settings/parameter-settings.component';
import { CommandSettingsComponent } from './command-settings/command-settings.component';
import { ModelSettingsComponent } from './model-settings/model-settings.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { MachineSettingsComponent } from './machine-settings/machine-settings.component';
import { UserTypeSettingsComponent } from './user-type-settings/user-type-settings.component';
import { MachineMoreComponent } from './machine-more/machine-more.component';
import { MachineGuard } from './guards/machine.guard';
import { UserTypeGuard } from './guards/user-type.guard';
import { UserTypeMoreComponent } from './user-type-more/user-type-more.component';
import { UserMoreComponent } from './user-more/user-more.component';
import { UserGuard } from './guards/user.guard';
import { ParameterMoreComponent } from './parameter-more/parameter-more.component';
import { ParameterGuard } from './guards/parameter.guard';
import { CommandMoreComponent } from './command-more/command-more.component';
import { CommandGuard } from './guards/command.guard';
import { TelemetryGuard } from './guards/telemetry.guard';
import { ModelMoreComponent } from './model-more/model-more.component';
import { ModelGuard } from './guards/model.guard';
import { TelemetryGroupSettingsComponent } from './telemetry-group-settings/telemetry-group-settings.component';
import { TelemetryGroupMoreComponent } from './telemetry-group-more/telemetry-group-more.component';
import { MachineTestComponent } from './machine-test/machine-test.component';
import { FirmwareIotSettingsComponent } from './firmware-iot-settings/firmware-iot-settings.component';
import { FirmwareSettingsComponent } from './firmware-settings/firmware-settings.component';
import { FirmwareMoreComponent } from './firmware-more/firmware-more.component';
import { FirmwareIotUpdateComponent } from './firmware-iot-udate/firmware-iot-update.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { animation: 'LoginPage' } },
  { path: 'labels', component: LabelSettingsComponent, canActivate: [AuthGuard], data: { animation: 'LabelPage' } },
  { path: 'commands', component: CommandSettingsComponent, canActivate: [AuthGuard], data: { animation: 'CommandsPage' } },
  { path: 'command-details', component: CommandMoreComponent, canActivate: [CommandGuard], data: { animation: 'CommandsDetailsPage' } },
  { path: 'parameters', component: ParameterSettingsComponent, canActivate: [AuthGuard], data: { animation: 'ParametersPage' } },
  { path: 'parameter-details', component: ParameterMoreComponent, canActivate: [ParameterGuard], data: { animation: 'ParametersDetailsPage' } },
  { path: 'models', component: ModelSettingsComponent, canActivate: [AuthGuard], data: { animation: 'ModelsPage' } },
  { path: 'model-details', component: ModelMoreComponent, canActivate: [ModelGuard], data: { animation: 'ModelsDetailsPage' } },
  { path: 'users', component: UserSettingsComponent, canActivate: [AuthGuard], data: { animation: 'UsersPage' } },
  { path: 'user-details', component: UserMoreComponent, canActivate: [UserGuard], data: { animation: 'UsersDetailsPage' } },
  { path: 'telemetry-group', component: TelemetryGroupSettingsComponent, canActivate: [AuthGuard], data: { animation: 'TelemetryGroupPage' } },
  { path: 'telemetry-group-details', component: TelemetryGroupMoreComponent, canActivate: [TelemetryGuard], data: { animation: 'TelemetryGroupDetailsPage' } },
  { path: 'roles', component: UserTypeSettingsComponent, canActivate: [AuthGuard], data: { animation: 'RolesPage' } },
  { path: 'role-details', component: UserTypeMoreComponent, canActivate: [UserTypeGuard], data: { animation: 'RolesDetailsPage' } },
  { path: 'machines', component: MachineSettingsComponent, canActivate: [AuthGuard], data: { animation: 'MachinesPage' } },
  { path: 'machine-details', component: MachineMoreComponent, canActivate: [MachineGuard], data: { animation: 'MachinesDetailsPage' } },
  { path: 'machine-test', component: MachineTestComponent, canActivate: [MachineGuard], data: { animation: 'MachinesTestPage' } },
  { path: 'firmware-iot', component: FirmwareIotSettingsComponent, canActivate: [AuthGuard], data: { animation: 'FirmwareIotPage' } },
  { path: 'firmware-iot-update', component: FirmwareIotUpdateComponent, canActivate: [AuthGuard], data: { animation: 'FirmwareIotUpdatePage' } },
  { path: 'firmware', component: FirmwareSettingsComponent, canActivate: [AuthGuard], data: { animation: 'FirmwarePage' } },
  { path: 'firmware-details', component: FirmwareMoreComponent, canActivate: [AuthGuard], data: { animation: 'FirmwareDetailsPage' } },
  { path: '', redirectTo: '/machines', pathMatch: 'full' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
