import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { GenericCrudService } from '../services/generic-crud.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IModelObj } from '../models/iModelObj';
import { environment } from 'src/environments/environment';


export abstract class GenericCrudComponent<Gen extends IModelObj, ID> implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<Gen>;
  listElements: Gen[];
  filterString: string;
  fil: string = '';

  constructor(
    protected crudService: GenericCrudService<Gen, ID>,
    protected alertService: AlertService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fil = sessionStorage.getItem(this.filterString) != null ? sessionStorage.getItem(this.filterString) : '';
    this.load();
  }

  reload() {
    this.crudService.load();
  }

  load() {
    return this.crudService.listObservable.subscribe(
      (data: Gen[]) => {
        this.listElements = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.applyFilter(this.fil);
        this.setCustomDataAccessor();
      this.setFilterPredicate();
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )
  }

  onChange($event) {
    this.applyFilter($event);
    this.setCustomDataAccessor();
    this.setFilterPredicate();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
    }
  }

  setCustomDataAccessor(){
  }

  setFilterPredicate(){
  }

  showEditDialog(dialog: any, element: Gen, width: number = 400) {
    const dialogRef = this.dialog.open(dialog, {
      width: width + 'px',
      data: {
        listElements: this.listElements,
        element: element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.reload();
    });
  }


  getColor(idnode: string) {
    try {
      return environment.IDNODELIST.find(x => x.value === idnode).color;
    }
    catch {
      return 'warn';
    }
  }

  more(row: Gen) {
    sessionStorage.setItem(this.filterString, this.fil);
  }

  abstract edit(row: Gen): void;

  abstract showAddDialog(): void;
}
